.footerList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footerLink {
  color: #b3b3b3;
  text-decoration: none;
  font-size: 14px;
  display: block;
  margin: 5px 0;
  transition: color 0.3s ease;
}

.footerLink:hover {
  color: white;
}

/* Centering the social icons under "Follow us" */
.socialIcons {
  display: flex;
  justify-content: center;
  gap: 10px; /* Spacing between icons */
}

/* Social media icons styling */
.MuiIconButtonRoot {
  color: #b3b3b3;
  transition: color 0.3s ease;
}

.MuiIconButtonRoot:hover {
  color: white;
}
